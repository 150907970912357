<template>
  <div>
    <v-btn
      fixed
      fab
      bottom
      left
      @click="drawer = true"
      class="ml-n12"
      >
      <v-icon
        class="ml-6"
        >
        mdi-chevron-right
      </v-icon>
    </v-btn>
    <v-navigation-drawer 
      v-if="currentUser"
      v-model="drawer"
      :mobile-breakpoint="762"
      app
      >
      <v-list
        class="fill-height"
        dense
        nav
        >
        <v-list-item>
          <v-list-item-content>
            <v-img
              height="50"
              contain
              :src="require('@/assets/full_logo.png')"
              ></v-img>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          class="mt-3"
          link
          exact
          :to="{ name: 'pf_admin' }"
          active-class="secondary--text"
          >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-3"
          link
          exact
          :to="{ name: 'pf_admin_commissions' }"
          active-class="secondary--text"
          >
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Comisiones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-3"
          link
          exact
          :to="{ name: 'pf_admin_orders' }"
          active-class="secondary--text"
          >
          <v-list-item-icon>
            <v-icon>mdi-ticket</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Órdenes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-3"
          link
          exact
          :to="{ name: 'pf_admin_fairs' }"
          active-class="secondary--text"
          >
          <v-list-item-icon>
            <v-icon>mdi-string-lights</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ferias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      drawer: false
    }
  },

  mounted () {
    this.drawer = !this.$vuetify.breakpoint.mobile
  },

  computed: {
    ...mapGetters(['currentUser']),
  }
}
</script>
